export default [
  {
    icon: 'facebook',
    route: 'https://www.facebook.com/NTG.Innovations',
    platform: 'Facebook',
    username: 'NTG'
  },
  {
    icon: 'instagram',
    route: 'https://www.instagram.com/ntg_innovations',
    platform: 'Instagram',
    username: 'ntg_innovations'
  },
  {
    icon: 'youtube',
    route: 'https://www.youtube.com/@NTGInnovations',
    platform: 'YouTube',
    username: 'NTG Innovations'
  }
];