<template>
  <a :href="item.route" :class="{ 'mb-2': item.id !== 2 }" target="_blank" class="h-12 flex items-center py-2 px-4 bg-slate-700/25 shadow rounded transition-none hover:bg-slate-700/50">
    <div class="w-8 text-slate-300">
      <fa-icon :icon="['fab', item.icon]"></fa-icon>
    </div>
    <div class="flex flex-col flex-1">
      <span class="text-sm text-slate-300">
        {{ item.platform }}
      </span>
      <span class="text-xs text-slate-400">
        {{ item.username }}
      </span>
    </div>
  </a>
</template>

<script>
  export default {
    name: 'SeedItem',
    props: {
      item: Object
    }
  };
</script>