<template>
  <div class="h-screen py-8 bg-slate-600">
    <div class="w-80 mx-auto">

      <h1 class="text-3xl text-center text-white font-bold italic">NTG Innovations</h1>

      <div class="my-8">
        <template v-for="(item, i) in seed" :key="i">
          <transition name="slide" appear>
            <SeedItem :item="{ ...item, id: i }" :style="{ transitionDelay: `${i * 0.25}s` }" />
          </transition>
        </template>
      </div>

      <transition name="fade" appear>
        <div class="text-sm text-center text-slate-300 italic">Die Website befindet sich in Bearbeitung</div>
      </transition>

    </div>
  </div>
</template>

<script>
  import seed from './seed';

  import SeedItem from './SeedItem';

  export default {
    name: 'App',
    data: () => ({ seed }),
    components: {
      SeedItem
    }
  };
</script>

<style>
  /* Tailwind CSS */

  @tailwind base;
  @tailwind components;
  @tailwind utilities;

  /* Transitions */

  .slide-enter-from
  {
    opacity: 0;
    transform: translateY(1rem);
  }
  .slide-enter-to 
  {
    opacity: 1;
    transform: translateY(0);
  }
  .slide-enter-active 
  {
    transition: all 0.25s ease-in-out;
  }

  .fade-enter-from
  {
    opacity: 0;
  }
  .fade-enter-to 
  {
    opacity: 1;
  }
  .fade-enter-active 
  {
    transition: opacity 0.25s ease-in-out;
    transition-delay: 1s;
  }
</style>