import { createApp } from 'vue';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFacebook, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';

import App from './App';

library.add(faFacebook, faInstagram, faYoutube);

const app = createApp(App);

app.component('fa-icon', FontAwesomeIcon);

app.mount('#app');